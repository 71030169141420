import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Box, Button, IconButton, LinearProgress, Paper, Typography, Stack, Grid2 } from "@mui/material";
import { useParams } from "react-router-dom";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { useState, useMemo, useCallback } from "react";
import { DocumentForm } from "./DocumentForm";
import { Modal } from "../../components/shared/Modal";
import { useKairosApi } from "../../hooks/useKairosApi";
import { useHealthRecordDocuments } from "../../hooks/useHealthRecordDocuments";
import { Document, DocumentCategoryEnum } from "@syadem/kairos-citizen-js";
import { ListSortIcon } from "../../components/SortIcon";
import { EditDocumentForm } from "./EditDocumentForm";

interface DocumentListProps {
  documents: Document[];
  onDownload: (documentId: string, filename: string) => void;
  onDelete: (documentId: string) => void;
  onPreview: (documentId: string, filename: string) => void;
  onEdit: (document: Document) => void;
}

function DocumentHeader({
  updateSort,
  order,
  orderByState,
}: {
  updateSort: (newOrderBy: string) => void;
  order: "asc" | "desc";
  orderByState: string;
}) {
  const { t } = useI18n();

  return (
    <Grid2
      container
      sx={{
        px: 2,
        py: 2,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
        mb: 0.3,
        display: { xs: "none", md: "flex" },
      }}
    >
      <Grid2 container size={11}>
        <Grid2 container size={4} alignItems="center">
          <Typography variant="subtitle2" fontWeight={600}>
            {t("documents.attributes.name")}
          </Typography>
          <ListSortIcon selected={orderByState === "name"} order={order} onClick={() => updateSort("name")} />
        </Grid2>
        <Grid2 container size={2} alignItems="center">
          <Typography variant="subtitle2" fontWeight={600}>
            {t("documents.attributes.category")}
          </Typography>
          <ListSortIcon selected={orderByState === "category"} order={order} onClick={() => updateSort("category")} />
        </Grid2>
        <Grid2 container size={2} alignItems="center">
          <Typography variant="subtitle2" fontWeight={600}>
            {t("documents.attributes.date")}
          </Typography>
          <ListSortIcon selected={orderByState === "date"} order={order} onClick={() => updateSort("date")} />
        </Grid2>
        <Grid2 container size={4} alignItems="center">
          <Typography variant="subtitle2" fontWeight={600}>
            {t("documents.attributes.attachments")}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 flex={1} />
    </Grid2>
  );
}

function DocumentList({ documents, onDownload, onDelete, onPreview, onEdit }: DocumentListProps) {
  const { t } = useI18n();
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("date");

  const updateSort = useCallback(
    (newOrderBy: string) => {
      if (newOrderBy === orderBy) {
        setOrder(order === "asc" ? "desc" : "asc");
      } else {
        setOrderBy(newOrderBy);
        setOrder("asc");
      }
    },
    [order, orderBy],
  );

  const sortedDocuments = useMemo(() => {
    const getValueFn = (document: Document): string => {
      switch (orderBy) {
        case "name":
          return document.name;
        case "category":
          return document.category;
        default:
          return "";
      }
    };

    const sortFn = (a: Document, b: Document) => {
      if (orderBy === "date") {
        if (!a.date) return 1;
        if (!b.date) return -1;
        return order === "asc" ? a.date.getTime() - b.date.getTime() : b.date.getTime() - a.date.getTime();
      }

      const aValue = getValueFn(a);
      const bValue = getValueFn(b);
      return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    };

    return [...documents].sort(sortFn);
  }, [documents, order, orderBy]);

  return (
    <>
      <DocumentHeader updateSort={updateSort} order={order} orderByState={orderBy} />
      <Box>
        {sortedDocuments.map((document) => (
          <Grid2
            container
            key={document.id}
            sx={{
              bgcolor: "background.paper",
              mb: 0.2,
              border: `solid 1px ${theme.palette.neutral[200]}`,
              borderRadius: 1,
              py: 1,
              px: 2,
              "&:hover": {
                border: `solid 1px ${theme.palette.primary[500]}`,
                cursor: "pointer",
              },
            }}
            onClick={() => onEdit(document)}
          >
            <Grid2 container size={11} alignItems="center">
              <Grid2 size={{ xs: 12, md: 4 }}>
                <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {document.name}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 2 }}>
                <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {t(`documents.attributes.categories.${document.category}`)}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 2 }}>
                <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {document.date?.toLocaleDateString()}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                {document.attachments?.map((filename) => (
                  <Box key={filename} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {filename}
                    </Typography>
                    <IconButton 
                      color="primary" 
                      sx={{ p: 0.2, ml: 2 }} 
                      onClick={(e) => {
                        e.stopPropagation();
                        onPreview(document.id, filename);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      sx={{ p: 0.2, ml: 0.5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDownload(document.id, filename);
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Grid2>
            </Grid2>
            <Grid2 size={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton 
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(document.id);
                }} 
                size="small" 
                color="error"
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Grid2>
          </Grid2>
        ))}
      </Box>
    </>
  );
}

export function Documents() {
  const { id } = useParams() as { id: string };
  const { t } = useI18n();
  const { healthRecord, error: healthRecordError, isLoading: healthRecordLoading } = useHealthRecord(id);
  const {
    documents,
    error: documentsError,
    isLoading: documentsLoading,
    refreshDocuments,
  } = useHealthRecordDocuments(id);
  const [isAddDocumentOpen, setIsAddDocumentOpen] = useState(false);
  const [editingDocument, setEditingDocument] = useState<Document | null>(null);
  const api = useKairosApi();

  const handleDownload = async (documentId: string, filename: string) => {
    if (!api?.documentFileApi) {
      console.error("Document file API not available");
      return;
    }
    try {
      const blob = await api.documentFileApi.downloadFile(id, documentId, filename);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handlePreview = async (documentId: string, filename: string) => {
    if (!api?.documentFileApi) {
      console.error("Document file API not available");
      return;
    }
    try {
      const blob = await api.documentFileApi.downloadFile(id, documentId, filename);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.error("Error previewing file:", error);
    }
  };

  const handleDelete = async (documentId: string) => {
    if (!api?.documentApi) return;
    try {
      await api.documentApi.deleteDocument(id, documentId);
      refreshDocuments();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleCloseAddDocument = () => {
    setIsAddDocumentOpen(false);
    refreshDocuments();
  };

  const handleCloseEditDocument = () => {
    setEditingDocument(null);
    refreshDocuments();
  };

  return (
    <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
      {(healthRecordError || documentsError) && (
        <Typography sx={{ my: 4 }} align="center" component="div">
          <Alert severity="error">{t("common.alerts.alert_notification")}</Alert>
        </Typography>
      )}
      {(healthRecordLoading || documentsLoading) && <LinearProgress variant="query" sx={{ my: 2 }} />}
      {!healthRecordLoading && !documentsLoading && healthRecord && (
        <>
          {!documents || documents.length === 0 ? (
            <EmptyListPlaceholder
              icon={<ArticleIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
              title={t("documents.no_documents")}
              button={
                <Button
                  size="large"
                  onClick={() => setIsAddDocumentOpen(true)}
                  variant="contained"
                  startIcon={<AddIcon />}
                  disableElevation
                >
                  {t("documents.add_document")}
                </Button>
              }
            >
              {t("documents.add_document_msg")}
            </EmptyListPlaceholder>
          ) : (
            <Box sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">{t("documents.title")}</Typography>
                <Button
                  onClick={() => setIsAddDocumentOpen(true)}
                  variant="contained"
                  startIcon={<AddIcon />}
                  disableElevation
                >
                  {t("documents.add_document")}
                </Button>
              </Stack>
              <DocumentList
                documents={documents}
                onDownload={handleDownload}
                onDelete={handleDelete}
                onPreview={handlePreview}
                onEdit={setEditingDocument}
              />
            </Box>
          )}
          <Modal open={isAddDocumentOpen} onClose={handleCloseAddDocument} title={t("documents.add_document")}>
            <DocumentForm onClose={handleCloseAddDocument} />
          </Modal>
          <Modal 
            open={!!editingDocument} 
            onClose={handleCloseEditDocument} 
            title={t("documents.edit_document")}
          >
            {editingDocument && <EditDocumentForm document={editingDocument} onClose={handleCloseEditDocument} />}
          </Modal>
        </>
      )}
    </Paper>
  );
}
