import {
  AccountApi,
  Configuration,
  HealthProfileApi,
  HealthRecordApi,
  RecordSharingApi,
  SharingRequestApi,
  VaccinationActApi,
  DocumentApi,
} from "@syadem/kairos-citizen-js";
import { KairosCertApi } from "./kairosCertApi";
import { DocumentFileApi } from "./DocumentFileApi";
import { ApiClient as ArianeApi, initAriane } from "@syadem/ariane-js";
import { SadvApi, Configuration as SadvApiConfiguration } from "@syadem/sad-js";

interface ApisDependencies {
  kairosCitizenApiBasePath: string;
  kairosCitizenFileServerApiBasePath: string;
  kairosCertApiBasePath: string;
  sadApiBasePath: string;
  arianeApiBasePath: string;
  accessToken: () => Promise<string>;
}

export interface Apis {
  healthRecordApi: HealthRecordApi;
  sharingRequestApi: SharingRequestApi;
  vaccinationActApi: VaccinationActApi;
  healthProfile: HealthProfileApi;
  recordSharingApi: RecordSharingApi;
  accountApi: AccountApi;
  kairosCertApi: KairosCertApi;
  sadApi: SadvApi;
  arianeApi: ArianeApi;
  documentApi: DocumentApi;
  documentFileApi: DocumentFileApi;
}

export function initApis(dependencies: ApisDependencies): Apis {
  const kairosCitizenApiConfig = new Configuration({
    basePath: dependencies.kairosCitizenApiBasePath,
    accessToken: dependencies.accessToken,
  });
  return {
    healthRecordApi: new HealthRecordApi(kairosCitizenApiConfig),
    sharingRequestApi: new SharingRequestApi(kairosCitizenApiConfig),
    vaccinationActApi: new VaccinationActApi(kairosCitizenApiConfig),
    healthProfile: new HealthProfileApi(kairosCitizenApiConfig),
    recordSharingApi: new RecordSharingApi(kairosCitizenApiConfig),
    accountApi: new AccountApi(kairosCitizenApiConfig),
    kairosCertApi: new KairosCertApi(kairosCitizenApiConfig, dependencies.kairosCertApiBasePath),
    sadApi: new SadvApi(new SadvApiConfiguration({ basePath: dependencies.sadApiBasePath })),
    arianeApi: initAriane(dependencies.arianeApiBasePath),
    documentApi: new DocumentApi(kairosCitizenApiConfig),
    documentFileApi: new DocumentFileApi(
      new Configuration({
        basePath: dependencies.kairosCitizenFileServerApiBasePath,
        accessToken: dependencies.accessToken,
      }),
      dependencies.kairosCitizenFileServerApiBasePath,
    ),
  };
}
