import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import { IconButton } from "@mui/material";

function ListSortIcon({
  selected,
  order,
  onClick,
}: {
  selected: boolean;
  order: "asc" | "desc" | undefined;
  onClick: () => void;
}) {
  return (
    <IconButton
      size="small"
      onClick={onClick}
      sx={{
        transform: selected && order === "asc" ? "rotate(180deg)" : "none",
        transition: "transform 0.3s ease-in-out",
        p: 0,
        ml: 1,
      }}
    >
      {selected ? <ArrowDownwardIcon fontSize="small" /> : <SortIcon fontSize="small" />}
    </IconButton>
  );
}
export { ListSortIcon };

