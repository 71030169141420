import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Link,
  Skeleton,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import {
  Link as RouterLink,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useKairosApi } from "../hooks/useKairosApi";
import { DeleteButtonIcon } from "../components/shared/DeleteButtonIcon";
import { calculateAge, utcToday } from "../../utils/ageCalculator";
import { useHealthRecord } from "../hooks/useHealthRecord";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ResponseError } from "@syadem/kairos-citizen-js";
import { useAuthenticatedUser } from "../hooks";
import { dayjsWithLocale } from "../../utils/dayjs";

export function HealthRecord() {
  const { id } = useParams() as { id: string };
  const { healthRecord, error } = useHealthRecord(id);
  const { t, locale } = useI18n();
  const navigate = useNavigate();
  const api = useKairosApi();
  const currentUser = useAuthenticatedUser();

  useEffect(() => {
    if (error && error instanceof Response && error.status === 404 || error instanceof ResponseError && error.response.status === 404) {
      navigate("/not_found");
    }
  }, [error, navigate]);

  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <PageLayout
      title={
        breakpointMd ? (
          <Box sx={{ ml: 1 }}>
            <Link
              underline="none"
              color={theme.palette.primary.main}
              to="/"
              component={RouterLink}
              sx={{
                "&:hover": {
                  color: theme.palette.primary[300],
                },
              }}
            >
              <Typography variant={breakpointSm ? "h6" : "h5"}>{t("health_records.title")}</Typography>
            </Link>
            <Typography
              variant="h6"
              sx={{
                fontSize: 16,
                maxWidth: "240px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {healthRecord ? (
                `${healthRecord.firstNames} ${healthRecord.lastName} ${
                  healthRecord.customaryName ? `(${healthRecord.customaryName})` : ""
                }`
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
            <Typography variant={"subtitle2"} component="span" sx={{ color: theme.palette.neutral[500], fontSize: 14 }}>
              {healthRecord ? (
                `${dayjsWithLocale(healthRecord.birthDate, locale)} (${calculateAge(utcToday(), healthRecord.birthDate, true, t)})`
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
            {api &&
              healthRecord &&
              (healthRecord.ownerId === currentUser?.id ? (
                <DeleteButtonIcon
                  deleteRecord={() => api.healthRecordApi.deleteHealthRecord(healthRecord.id)}
                  title={t("common.cta.delete")}
                  modalTitle={t("health_records.delete_hr")}
                  refreshCallback={() => navigate(`/health-records`)}
                  modalContent={
                    <>
                      <div>{t("health_records.delete_hr_validation_intro")}</div>
                      <p>{t("health_records.delete_hr_validation")}</p>
                    </>
                  }
                  icon={<DeleteOutlineIcon fontSize="small" color="error" />}
                  variant="contained"
                  type={"icon"}
                  sx={{ p: "4px 8px" }}
                />
              ) : (
                <DeleteButtonIcon
                  deleteRecord={() => api.healthRecordApi.leaveHealthRecord(healthRecord.id)}
                  refreshCallback={() => navigate(`/health-records`)}
                  title={t("share.withdraw")}
                  modalTitle={t("share.withdraw_hr")}
                  modalContent={t("share.withdraw_hr_validation")}
                  icon={<ExitToAppIcon fontSize="small" color="error" />}
                  variant="outlined"
                  type={"icon"}
                  sx={{ p: "4px 8px" }}
                />
              ))}
          </Box>
        ) : (
          <>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: theme.palette.neutral[600], fontWeight: "bold" }}
              data-testid="breadcrumbs"
              separator={<NavigateNextIcon fontSize={breakpointSm ? "small" : "medium"} />}
            >
              <Link
                underline="none"
                color={theme.palette.primary.main}
                to="/"
                component={RouterLink}
                sx={{
                  "&:hover": {
                    color: theme.palette.primary[300],
                  },
                }}
              >
                <Typography variant={breakpointSm ? "h6" : "h5"}>{t("health_records.title")}</Typography>
              </Link>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography
                  variant={breakpointSm ? "h6" : "h5"}
                  sx={{
                    maxWidth: "450px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {healthRecord ? (
                    <>
                      {`${healthRecord.firstNames} ${healthRecord.lastName} ${
                        healthRecord.customaryName ? `(${healthRecord.customaryName})` : ""
                      }`}
                    </>
                  ) : (
                    <Skeleton variant="text" width={150} />
                  )}
                </Typography>
                <Typography variant={"h6"} component="span" sx={{ color: theme.palette.neutral[500], ml: 2 }}>
                  {healthRecord ? (
                    `(${calculateAge(utcToday(), healthRecord.birthDate, true, t)})`
                  ) : (
                    <Skeleton variant="text" width={150} />
                  )}
                </Typography>

                {api &&
                  healthRecord &&
                  (healthRecord.ownerId === currentUser?.id ? (
                    <DeleteButtonIcon
                      deleteRecord={() => api.healthRecordApi.deleteHealthRecord(healthRecord.id)}
                      title={t("common.cta.delete")}
                      modalTitle={t("health_records.delete_hr")}
                      refreshCallback={() => navigate(`/health-records`)}
                      modalContent={
                        <>
                          <div>{t("health_records.delete_hr_validation_intro")}</div>
                          <p>{t("health_records.delete_hr_validation")}</p>
                        </>
                      }
                      icon={<DeleteOutlineIcon fontSize="small" color="error" />}
                      variant="contained"
                      type={"icon"}
                      sx={{ p: "4px 8px" }}
                    />
                  ) : (
                    <DeleteButtonIcon
                      deleteRecord={() => api.healthRecordApi.leaveHealthRecord(healthRecord.id)}
                      refreshCallback={() => navigate(`/health-records`)}
                      title={t("share.withdraw")}
                      modalTitle={t("share.withdraw_hr")}
                      modalContent={t("share.withdraw_hr_validation")}
                      icon={<ExitToAppIcon fontSize="small" color="error" />}
                      variant="outlined"
                      type={"icon"}
                      sx={{ p: "4px 8px" }}
                    />
                  ))}
              </Box>
            </Breadcrumbs>
          </>
        )
      }
      tabs={<PageTabs />}
    >
      <Outlet />
    </PageLayout>
  );
}

function PageTabs() {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  let currentTab = "health-records/:id/shares";
  const { t } = useI18n();
  const { pathname } = useLocation();

  if (
    matchPath("health-records/:id/vaccination/:vaccinationActId", pathname) ||
    matchPath("health-records/:id/add-vaccination", pathname) ||
    matchPath("health-records/:id", pathname)
  ) {
    currentTab = "health-records/:id";
  } else if (matchPath("health-records/:id/edit", pathname)) {
    currentTab = "health-records/:id/edit";
  } else if (matchPath("health-records/:id/profile", pathname)) {
    currentTab = "health-records/:id/profile";
  } else if (matchPath("health-records/:id/diagnosis", pathname)) {
    currentTab = "health-records/:id/diagnosis";
  } else if (matchPath("health-records/:id/documents", pathname)) {
    currentTab = "health-records/:id/documents";
  }
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Tabs
      value={currentTab}
      textColor="primary"
      sx={{
        backgroundColor: "none",
        border: "none",
        px: { xs: 0, md: 2 },
        ".MuiButtonBase-root": {
          padding: { xs: "0 8px", md: "0 16px" },
        },
        ".MuiTabScrollButton-root": {
          color: theme.palette.primary[500],
        },
      }}
      variant={breakpointSm ? "fullWidth" : "standard"}
    >
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.immunization")}
          </Typography>
        }
        value="health-records/:id"
        to=""
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.profile")}
          </Typography>
        }
        value="health-records/:id/profile"
        to="profile"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.vaccinations_to_be_done")}
          </Typography>
        }
        value="health-records/:id/diagnosis"
        to="diagnosis"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.identity")}
          </Typography>
        }
        value="health-records/:id/edit"
        to="edit"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.share")}
          </Typography>
        }
        value="health-records/:id/shares"
        to="shares"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.documents")}
          </Typography>
        }
        value="health-records/:id/documents"
        to="documents"
        component={RouterLink}
      />
    </Tabs>
  );
}
