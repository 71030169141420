import { Document } from "@syadem/kairos-citizen-js/dist/generated/models/Document";
import { useCallback, useEffect, useState } from "react";
import { useKairosApi } from "./useKairosApi";

export function useHealthRecordDocuments(id: string): {
  isLoading: boolean;
  documents?: Document[];
  error?: Response | Error;
  refreshDocuments: () => void;
} {
  const api = useKairosApi();

  const [documents, setDocuments] = useState<Document[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const refreshDocuments = useCallback(() => {
    setRefreshCounter((prev) => prev + 1);
  }, []);

  useEffect(() => {
    (async () => {
      if (api?.documentApi) {
        try {
          const { documents } = await api.documentApi.indexDocuments(id);
          setDocuments(documents);
        } catch (error) {
          if (error instanceof Response || error instanceof Error) {
            setError(error);
          } else {
            throw error;
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [api, id, refreshCounter]);

  return { isLoading, documents, error, refreshDocuments };
} 