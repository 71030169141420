import { Configuration, Document } from "@syadem/kairos-citizen-js";

export interface DocumentFileApiInterface {
  attachFiles(healthRecordId: string, documentId: string, attachments: File[]): Promise<Document>;
  downloadFile(healthRecordId: string, documentId: string, fileName: string): Promise<Blob>;
}

export class DocumentFileApi implements DocumentFileApiInterface {
  constructor(
    private readonly configuration: Configuration,
    private readonly basePath: string,
  ) {}

  async attachFiles(healthRecordId: string, documentId: string, attachments: File[]): Promise<Document> {
    const formData = new FormData();

    attachments.forEach((a) => formData.append("attachments[]", a));

    let token = "";
    if (this.configuration.accessToken) {
      token = await this.configuration.accessToken();
    }

    const response = await fetch(`${this.basePath}/health-records/${healthRecordId}/documents/${documentId}/attach`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Accept": "application/json",
      },
      body: formData,
      mode: "cors",
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Upload failed with response:", errorText);
      throw new Error(`Failed to upload files: ${response.status} ${response.statusText}`);
    }

    const body = await response.json();
    const document: Document = {
      id: body.id,
      healthRecordId: healthRecordId,
      name: body.name,
      category: body.category,
      attachments: body.attachments,
      date: body.date ? new Date(body.date) : null,
      createdAt: new Date(body.created_at),
    };

    return document;
  }

  async downloadFile(healthRecordId: string, documentId: string, filename: string): Promise<Blob> {
    let token = "";
    if (this.configuration.accessToken) {
      token = await this.configuration.accessToken();
    }

    const url = new URL(`${this.basePath}/health-records/${healthRecordId}/documents/${documentId}`);
    url.searchParams.append("filename", filename);

    const response = await fetch(url, { 
      method: "GET", 
      headers: {
        "Authorization": `Bearer ${token}`,
        "Accept": "application/octet-stream",
      },
      mode: "cors",
    });

    return response.blob();
  }
}
