import { LoadingButton } from "@mui/lab";
import { Button, FormControl, MenuItem, Stack, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../hooks/useI18n";
import { useKairosApi } from "../../hooks/useKairosApi";
import {
  CreateDocumentBodyDocument,
  CreateDocumentBodyDocumentCategoryEnum,
  DocumentCategoryEnum,
  Document,
} from "@syadem/kairos-citizen-js";
import { StyledSelect } from "../../components/mui/StyledSelect";
import { StyledInput } from "../../components/mui/StyledInput";
import StyledDatepicker from "../../components/mui/StyledDatepicker";
import dayjs, { Dayjs } from "dayjs";

interface EditDocumentFormProps {
  document: Document;
  onClose: () => void;
}

export function EditDocumentForm({ document, onClose }: EditDocumentFormProps) {
  const { id } = useParams() as { id: string };
  const { t } = useI18n();
  const api = useKairosApi();
  const [isSending, setIsSending] = useState(false);

  const [documentName, setDocumentName] = useState(document.name);
  const [documentCategory, setDocumentCategory] = useState<DocumentCategoryEnum>(document.category);
  const [date, setDate] = useState<Dayjs | null>(document.date ? dayjs(document.date) : null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!api?.documentApi) {
      return;
    }

    try {
      setIsSending(true);

      // Update document
      const documentData: CreateDocumentBodyDocument = {
        name: documentName,
        category: documentCategory,
        date: date ? date.toDate() : undefined,
      };

      await api.documentApi.updateDocument(id, document.id, { document: documentData });

      onClose();
    } catch (error) {
      console.error("Error in document update:", error);
      if (error instanceof Error) {
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
        });
      }
    } finally {
      setIsSending(false);
    }
  };

  const handleSelectCategory = (e: SelectChangeEvent<DocumentCategoryEnum>) => {
    setDocumentCategory(e.target.value as DocumentCategoryEnum);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={2} sx={{ minWidth: { xs: "100%", md: 350 }, pt: 1 }}>
        <FormControl fullWidth size="small" required>
          <StyledSelect<DocumentCategoryEnum>
            id="categoryInput"
            label={t("documents.attributes.category")}
            name="category"
            value={documentCategory}
            renderValue={(category) => t(`documents.attributes.categories.${category}`)}
            onChange={handleSelectCategory}
          >
            {Object.values(CreateDocumentBodyDocumentCategoryEnum).map((category) => (
              <MenuItem key={category} value={category}>
                {t(`documents.attributes.categories.${category}`)}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>

        <StyledInput
          id="nameInput"
          type="text"
          label={t("documents.attributes.name")}
          name="name"
          value={documentName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDocumentName(e.target.value)}
        />

        <StyledDatepicker
          fullWidth
          id="dateInput"
          label={t("documents.attributes.date")}
          name="date"
          value={date}
          placeholder={t("common.dates.datePlaceholder")}
          onChange={(date) => setDate(date ? date : null)}
        />

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ pt: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSending}
            disabled={!documentName || isSending}
          >
            {t("common.save")}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
} 