import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Button, FormControl, MenuItem, Stack, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { useKairosApi } from "../../hooks/useKairosApi";
import {
  CreateDocumentBodyDocument,
  CreateDocumentBodyDocumentCategoryEnum,
  DocumentCategoryEnum,
} from "@syadem/kairos-citizen-js";
import { StyledSelect } from "../../components/mui/StyledSelect";
import { StyledInput } from "../../components/mui/StyledInput";
import StyledDatepicker from "../../components/mui/StyledDatepicker";
import dayjs, { Dayjs } from "dayjs";

interface DocumentFormProps {
  onClose: () => void;
}

export function DocumentForm({ onClose }: DocumentFormProps) {
  const { id } = useParams() as { id: string };
  const { t } = useI18n();
  const api = useKairosApi();
  const [isSending, setIsSending] = useState(false);

  const formatDefaultDocumentName = useCallback(
    (category: DocumentCategoryEnum) => {
      return `${dayjs().format("YYYYMMDD")} - ${t(`documents.attributes.categories.${category}`)}`;
    },
    [t],
  );

  const [files, setFiles] = useState<File[]>([]);
  const [documentName, setDocumentName] = useState(
    formatDefaultDocumentName(CreateDocumentBodyDocumentCategoryEnum.VaccinationBook),
  );
  const [documentCategory, setDocumentCategory] = useState<DocumentCategoryEnum>(
    CreateDocumentBodyDocumentCategoryEnum.VaccinationBook,
  );
  const [date, setDate] = useState<Dayjs | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Starting document submission...");

    if (!files.length || !api?.documentApi || !api?.documentFileApi) {
      console.error("Missing required data:", {
        hasFiles: files.length > 0,
        hasApi: !!api?.documentApi,
        hasDocumentFileApi: !!api?.documentFileApi,
      });
      return;
    }

    try {
      setIsSending(true);

      // Create document
      const documentData: CreateDocumentBodyDocument = {
        name: documentName,
        category: documentCategory,
        date: date ? date.toDate() : undefined,
      };
      console.log("Creating document with data:", documentData);

      const document = await api.documentApi.createDocument(id, { document: documentData });
      console.log("Document created successfully:", document);

      // Upload files using DocumentFileApi
      await api.documentFileApi.attachFiles(id, document.id, files);
      console.log("Files uploaded successfully");

      onClose();
    } catch (error) {
      console.error("Error in document submission:", error);
      if (error instanceof Error) {
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
        });
      }
    } finally {
      setIsSending(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      setFiles(Array.from(fileList));
    }
  };

  const handleSelectCategory = (e: SelectChangeEvent<DocumentCategoryEnum>) => {
    setDocumentCategory(e.target.value as DocumentCategoryEnum);
    setDocumentName(e.target.value ? formatDefaultDocumentName(e.target.value as DocumentCategoryEnum) : "");
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={2} sx={{ minWidth: { xs: "100%", md: 350 }, pt: 1 }}>
        <FormControl fullWidth size="small" required>
          <StyledSelect<DocumentCategoryEnum>
            id="categoryInput"
            label={t("documents.attributes.category")}
            name="category"
            value={documentCategory}
            renderValue={(category) => t(`documents.attributes.categories.${category}`)}
            onChange={handleSelectCategory}
          >
            {Object.values(CreateDocumentBodyDocumentCategoryEnum).map((category) => (
              <MenuItem key={category} value={category}>
                {t(`documents.attributes.categories.${category}`)}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>

        <StyledInput
          id="nameInput"
          type="text"
          label={t("documents.attributes.name")}
          name="name"
          value={documentName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDocumentName(e.target.value)}
        />

        <StyledDatepicker
          fullWidth
          id="dateInput"
          label={t("documents.attributes.date")}
          name="date"
          value={date}
          placeholder={t("common.dates.datePlaceholder")}
          onChange={(date) => setDate(date ? date : null)}
        />

        <Box>
          <input style={{ display: "none" }} id="file-input" type="file" multiple onChange={handleFileChange} />
          <label htmlFor="file-input">
            <Button variant="outlined" component="span" fullWidth startIcon={<AddIcon />}>
              {t("documents.add_files")}
            </Button>
          </label>
          {files.length > 0 && (
            <Box sx={{ mt: 1 }}>
              {files.map((file, index) => (
                <Box key={index} sx={{ color: theme.palette.text.secondary }}>
                  {file.name}
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ pt: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSending}
            disabled={!files.length || !documentName || isSending}
          >
            {t("common.save")}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
}
